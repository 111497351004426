import React from "react"

import LandingSiteBrand from './site-brand';

const ContentHeader = (props) =>
{
  return <div className="content-header">
    <div className="container">
        <LandingSiteBrand className={props.className} />
    </div>
  </div>
}

export default ContentHeader;

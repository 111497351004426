import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { graphql } from 'gatsby';

import LayoutLanding from "../../components/layout-landing/layout";
import SEO from "../../components/seo";
import Banner from "../../components/banner";
import Section from "../../components/section";
import Image from "../../components/image";
import AdvTitle from '../../components/advTitle';
import ImagesWithListContainer from "../../components/images-with-list-container";
import LightboxContainer from "../../components/lightbox-container";
import Testimonial from "../../components/testimonial";
import AnyQuestions from "../../components/any-questions";
import params from "../../data/params";
import SliderBanner from "../../components/slider-banner";
import Signatures from "../../components/signatures";
import ContactForm from "../../components/contact-form";

import "../../styles/business-up.less";

class BusinessUp extends React.Component
{
    // Los componentes funcionales no tienen estado (también se les llama stateless components), por eso a veces uso la sintaxis de clase
    // Hay que definir el estado y su valor inicial en el constructor mediante this.state = {...}
    // La última sentencia en el constructor es para que el método displayFlashNotification tenga una referencia correcta de la variable 'this'.

    constructor(props)
    {
        super(props);
        this.state = {displayFailFlash: false, displayOkFlash: false};

        // Banner
        this.slides =
        [
            {
                imagePath:"/images/slides/investment-banner.png",
                alignment: 'left',
                textColor: 'white',
                title: "BusinessUp<br />Build your own platform in 10 minutes",
                subtitle: "Create your own online entrepreneurship platform with your brand and help your cients achieve success",
                dangerous: {title: true, subtitle: false}
            },
            {
                imagePath:"/images/slides/slide-general.jpg",
                alignment: 'left',
                textColor: 'white',
                title: "Your best ally to promote and finance good businesses",
                subtitle: "With our help, build your business development program, your innovation ecosystem, your investment club or your crowdfunding platform",
            },
        ]

        this.displayFlashNotification = this.displayFlashNotification.bind(this);
    }

    // Esta función se define aquí pero se ejecuta desde el componente ContactForm, se le pasa como parámetro en el render.
    displayFlashNotification = (status) =>
    {
      document.getElementById('notification-box').classList.remove('alert-danger');
      document.getElementById('notification-box').classList.remove('alert-success');

      if (status === 'err')
      {
        document.getElementById('notification-box').classList.add('alert-danger');
        this.setState({displayFailFlash: true});
      }

      if (status === 'ok')
      {
        document.getElementById('notification-box').classList.add('alert-success');
        this.setState({displayOkFlash: true});
      }

      var button = document.querySelector('.form-trigger button');
      button.disabled = false;

      setTimeout(() => {
        this.setState({displayFailFlash: false, displayOkFlash: false})
        document.getElementById('notification-box').classList.remove('alert-danger');
        document.getElementById('notification-box').classList.remove('alert-success');
      }, 5000);
    }

    // const product_features = [
    //     "Usage of your own domain",
    //     "Brand Look&Feel",
    //     "Own data and contents",
    //     "Editable pages",
    //     "Reception and filtering projects",
    //     "Goldsmith® model and analysis KPIs",
    //     "Business document management",
    //     "Online mentoring space",
    //     "Business showcase",
    //     "Events and community",
    //     "100% autonomous administration",
    //     "Available in Spanish, English and Portuguese",
    // ]

    render()
    {
        return (
            <LayoutLanding businessUp>
                <SEO
                    title={this.props.intl.formatMessage({id: "BusinessUp - Tools for business creation and access to investment"})}
                    description={this.props.intl.formatMessage({id: "BusinessUp is Adventurees SaaS for easily creating fully configurable entrepreneurial environments and investment communities."})}
                    location={this.props.location}
                    image="/images/sharing/home-products.jpg"
                />

                <SliderBanner slides={this.slides} />

                { /*
                <Banner
                    className="banner business-up"
                    colSize='col-md-8 align-self-end'
                    filename="investment-banner.png"
                    title={this.props.intl.formatMessage({id: "BusinessUp<br />Build your own platform in 10 minutes"})}
                    subtitle={this.props.intl.formatMessage({id: "Create your own online entrepreneurship platform with your brand and help your cients achieve success"})}
                    dangerous={{title: true, subtitle: false}}
                    button={{text: 'Try it for free', url: params.DO_CREATE_URL, note: "No credit card required"}}
                />
                */ }

                <Section title={this.props.intl.formatMessage({id: "Functionalities"})}>
                    <div className="landing-product-features">
                        <div className="row justify-content-around">
                            <div className="col-md-5 functionality">
                                <div className="row justify-content-center">
                                    <div className="col-2 align-self-center functionality-icon">
                                        <img src="/images/functionalities/own-domain.png" alt="functionality" />
                                    </div>
                                    <div className="col-md-10 col-7 align-self-center functionality-text">
                                        <span>{this.props.intl.formatMessage({id: "Usage of your own domain"})}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 functionality">
                                <div className="row justify-content-center">
                                    <div className="col-2 align-self-center functionality-icon">
                                        <img src="/images/functionalities/own-design.png" alt="functionality" />
                                    </div>
                                    <div className="col-md-10 col-7 align-self-center functionality-text">
                                        <span>{this.props.intl.formatMessage({id: "Brand Look&Feel"})}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 functionality">
                                <div className="row justify-content-center">
                                    <div className="col-2 align-self-center functionality-icon">
                                        <img src="/images/functionalities/own-content.png" alt="functionality" />
                                    </div>
                                    <div className="col-md-10 col-7 align-self-center functionality-text">
                                        <span>{this.props.intl.formatMessage({id: "Own data and contents"})}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 functionality">
                                <div className="row justify-content-center">
                                    <div className="col-2 align-self-center functionality-icon">
                                        <img src="/images/functionalities/editable-pages.png" alt="functionality" />
                                    </div>
                                    <div className="col-md-10 col-7 align-self-center functionality-text">
                                        <span>{this.props.intl.formatMessage({id: "Editable pages"})}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 functionality">
                                <div className="row justify-content-center">
                                    <div className="col-2 align-self-center functionality-icon">
                                        <img src="/images/functionalities/project-filtering.png" alt="functionality" />
                                    </div>
                                    <div className="col-md-10 col-7 align-self-center functionality-text">
                                        <span>{this.props.intl.formatMessage({id: "Reception and filtering projects"})}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 functionality">
                                <div className="row justify-content-center">
                                    <div className="col-2 align-self-center functionality-icon">
                                        <img src="/images/functionalities/goldsmith-analysis.png" alt="functionality" />
                                    </div>
                                    <div className="col-md-10 col-7 align-self-center functionality-text">
                                        <span>{this.props.intl.formatMessage({id: "Goldsmith® model analysis"})}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 functionality">
                                <div className="row justify-content-center">
                                    <div className="col-2 align-self-center functionality-icon">
                                        <img src="/images/functionalities/kpis.png" alt="functionality" />
                                    </div>
                                    <div className="col-md-10 col-7 align-self-center functionality-text">
                                        <span>{this.props.intl.formatMessage({id: "Own diagnostic KPIs"})}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 functionality">
                                <div className="row justify-content-center">
                                    <div className="col-2 align-self-center functionality-icon">
                                        <img src="/images/functionalities/businesses-document-management.png" alt="functionality" />
                                    </div>
                                    <div className="col-md-10 col-7 align-self-center functionality-text">
                                        <span>{this.props.intl.formatMessage({id: "Business document management"})}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 functionality">
                                <div className="row justify-content-center">
                                    <div className="col-2 align-self-center functionality-icon">
                                        <img src="/images/functionalities/online-mentoring-space.png" alt="functionality" />
                                    </div>
                                    <div className="col-md-10 col-7 align-self-center functionality-text">
                                        <span>{this.props.intl.formatMessage({id: "Online mentoring space"})}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 functionality">
                                <div className="row justify-content-center">
                                    <div className="col-2 align-self-center functionality-icon">
                                        <img src="/images/functionalities/business-showcase.png" alt="functionality" />
                                    </div>
                                    <div className="col-md-10 col-7 align-self-center functionality-text">
                                        <span>{this.props.intl.formatMessage({id: "Business showcase"})}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 functionality">
                                <div className="row justify-content-center">
                                    <div className="col-2 align-self-center functionality-icon">
                                        <img src="/images/functionalities/events-and-community.png" alt="functionality" />
                                    </div>
                                    <div className="col-md-10 col-7 align-self-center functionality-text">
                                        <span>{this.props.intl.formatMessage({id: "Events and community"})}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 functionality">
                                <div className="row justify-content-center">
                                    <div className="col-2 align-self-center functionality-icon">
                                        <img src="/images/functionalities/autonomy.png" alt="functionality" />
                                    </div>
                                    <div className="col-md-10 col-7 align-self-center functionality-text">
                                        <span>{this.props.intl.formatMessage({id: "100% autonomous administration"})}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 functionality">
                                <div className="row justify-content-center">
                                    <div className="col-2 align-self-center functionality-icon">
                                        <img src="/images/functionalities/admin-support.png" alt="functionality" />
                                    </div>
                                    <div className="col-md-10 col-7 align-self-center functionality-text">
                                        <span>{this.props.intl.formatMessage({id: "Administrator training and support"})}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 functionality">
                                <div className="row justify-content-center">
                                    <div className="col-2 align-self-center functionality-icon">
                                        <img src="/images/functionalities/languages.png" alt="functionality" />
                                    </div>
                                    <div className="col-md-10 col-7 align-self-center functionality-text">
                                        <span>{this.props.intl.formatMessage({id: "Available in Spanish, English and Portuguese"})}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Section>

                <Section title={this.props.intl.formatMessage({id: "How can you benefit from our platform?"})} className="product-platform-types">
                    <ImagesWithListContainer elements={this.props.data.allSegmentsListJson.edges} className="services" />
                </Section>

                { /*
                    <Section title={this.props.intl.formatMessage({id: "Get to know the platform's insides"})} className="d-none d-xl-block">
                        <FunctionalityShowcase />
                    </Section>
                */ }

                {/*
                    <Section title={this.props.intl.formatMessage({id: "Set up your platform in 10 minutes"})} className="setup-steps d-none d-lg-block">
                        <ImageColumns columns={this.props.data.allSetupsJson.edges} className="purchase-steps" />
                        <div className="form-trigger">
                            <p className="free-trial-trigger-text">{this.props.intl.formatMessage({id: "Start now your FREE TRIAL for 7 days"})}</p>
                            <a href={params.DO_CREATE_URL}>{this.props.intl.formatMessage({id: "Start now"})}</a>
                        </div>
                    </Section>
                */}

                <Section title={this.props.intl.formatMessage({id: "How do our clients use BusinessUp?"})}>
                    <LightboxContainer columns={this.props.data.allBusinessupClientsJson.edges} className="clients-platforms" />
                </Section>

                <Section title={this.props.intl.formatMessage({id: "Testimonials"})} className="businessUp-testimmonials">
                    <div className="row testimonials justify-content-center">
                            {this.props.data.allTestimonialsJson.edges.map((element, index) => {
                                return <Testimonial key={index} testimonial={element.node} />
                            })}
                    </div>
                </Section>

                <Section className="gdpr-notice">
                    <div className="row">
                        <div className="col-md-2">
                            <img src="/images/common/gdpr-logo.png" alt="GDPR Logo"/>
                        </div>
                        <div className="col-md-10 align-self-center">
                            <p>
                                {this.props.intl.formatMessage({id: "At Adventurees we comply with the General Data Protection Regulation of the European Union. All the data you generate in your platform will be your property. See our "})}
                                <Link to="/privacy-policy"> {this.props.intl.formatMessage({id: "privacy policy"})} </Link> {this.props.intl.formatMessage({id: "to know more"})}.
                            </p>
                        </div>
                    </div>
                </Section>

                <AnyQuestions />

                <Section>

                    <div id="notification-box" className="col-md-12 notification-box alert">

                        {this.state.displayFailFlash && <p>Ha fallado el envío del formulario</p>}
                        {this.state.displayOkFlash && <p>Su mensaje ha sido enviado correctamente</p>}

                    </div>

                    <div>
                        <ContactForm displayFlashNotification={this.displayFlashNotification.bind(this)} />
                    </div>

                </Section>

                <Section>
                    <Signatures />
                </Section>

                <Section className="mb-5" subtitle={this.props.intl.formatMessage({id: "This company has received a participative loan from the \"Creation and Development of Innovative Technological SMEs\" line of the Canary Islands Fund, Finance 1, and has 85% co-financing from the European Regional Development Fund from the ERDF Operational Programme for the Canary Islands 2014-2020, contributing to the fulfilment of the objectives of priority axis 1 \"Strengthening research, technological development and innovation\", Specific Objective 1. 2.1 \"Encouragement and promotion of R&D&I activities led by companies, support for the creation and consolidation of innovative companies and support for innovative public procurement\"."})}></Section>

            </LayoutLanding>
        )
    }

}

export default injectIntl(BusinessUp);

export const setup = graphql`
    query LandingBusinessUpQuery
    {
        allSetupsJson {
            edges
            {
                node
                {
                    colSize
                    className
                    imageName
                    imageAlt
                    title
                }
            }
        },
        allHelpItemsJson {
            edges {
                node {
                    colSize
                    className
                    imageName
                    imageAlt
                    title
                    description
                }
            }
        }
        allBusinessupClientsJson {
            edges
            {
                node
                {
                    colSize
                    className
                    imageName
                    imagePath {
                        childImageSharp {
                            fluid {
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    imageAlt
                    text
                    type
                    link
                }
            }
        }
        allSegmentsListJson
        {
            edges
            {
                node
                {
                    wideTitle
                    colSize
                    className
                    imageName
                    imagePath
                    imageAlt
                    title
                    list { text }
                }
            }
        }

        allTestimonialsJson(limit: 3)
        {
            edges
            {
                node
                {
                    author {name imagePath job company}
                    content
                }
            }
        }
    }
`;

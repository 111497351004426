import React from "react"

export default class LandingSiteBrand extends React.Component {
  
  constructor(props)
  {
    super(props);
    let displayWhite = true;
    if (this.props.className.includes('fixed'))
    {
      displayWhite = false;
    }

    this.state = { displayWhite: displayWhite };
  }

  static getDerivedStateFromProps(props, state)
  {
    let displayWhite = true;
    if (props.className.includes("fixed"))
    {
      displayWhite = false;
    }
    return {displayWhite: displayWhite};
  }

  render()
  {
    return <div className="site-brand landing-site-brand">
      {!this.state.displayWhite && <img src={"/images/common/main-logo.png"} alt="Site logo"/>}
      {this.state.displayWhite && <img src={"/images/common/main-logo-white.png"} alt="Site logo"/>}
    </div>
  }
}

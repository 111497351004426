import React from "react"
import LanguageSwitcher from "./languageSwitcher";
import params from "../../../data/params";

const TopHeaderMenu = () => (
  <div className="top-header-menu">
    <LanguageSwitcher />
  </div>
)

export default TopHeaderMenu;
